import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-28c02e40"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row flex--nowrap" }
const _hoisted_2 = { class: "row flex--nowrap" }
const _hoisted_3 = { key: 3 }
const _hoisted_4 = { key: 4 }
const _hoisted_5 = { class: "row flex--nowrap" }
const _hoisted_6 = { class: "row flex--nowrap" }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { key: 5 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_va_icon = _resolveComponent("va-icon")!
  const _component_anp_popover = _resolveComponent("anp-popover")!
  const _component_va_button = _resolveComponent("va-button")!
  const _component_Settings = _resolveComponent("Settings")!
  const _component_LiveAgenda = _resolveComponent("LiveAgenda")!
  const _component_va_progress_circle = _resolveComponent("va-progress-circle")!
  const _component_va_input = _resolveComponent("va-input")!
  const _component_DropdownSearches = _resolveComponent("DropdownSearches")!
  const _component_DropdownSections = _resolveComponent("DropdownSections")!
  const _component_anp_column_item = _resolveComponent("anp-column-item")!
  const _component_anp_column_skeleton = _resolveComponent("anp-column-skeleton")!
  const _component_anp_column_empty = _resolveComponent("anp-column-empty")!
  const _component_anp_column_all_loaded = _resolveComponent("anp-column-all-loaded")!
  const _component_anp_column_loader = _resolveComponent("anp-column-loader")!
  const _component_anp_column = _resolveComponent("anp-column")!
  const _component_DropdownExpertRegions = _resolveComponent("DropdownExpertRegions")!
  const _component_anp_placeholder = _resolveComponent("anp-placeholder")!

  return (_ctx.resource.title !== 'ANP Bronnenbank')
    ? (_openBlock(), _createBlock(_component_anp_column, {
        key: 0,
        class: _normalizeClass({ big: _ctx.resizeIsBig }),
        id: _ctx.anchor,
        title: _ctx.resource.title,
        anchor: _ctx.anchor,
        onScrollTop: _ctx.handleScrollTop,
        ref: 
      (element) => {
        _ctx.root = element?.$el;
      }
    
      }, {
        actions: _withCtx(() => [
          (_ctx.draggable)
            ? (_openBlock(), _createBlock(_component_anp_popover, {
                key: 0,
                message: _ctx.$t('actions.drag')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_icon, {
                    class: "handle",
                    name: "drag_handle",
                    color: "gray"
                  })
                ]),
                _: 1
              }, 8, ["message"]))
            : _createCommentVNode("", true),
          _createVNode(_component_anp_popover, {
            message: 
          _ctx.resizeIsBig ? _ctx.$t('common.make-smaller') : _ctx.$t('common.make-wider')
        
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_button, {
                class: "resize",
                icon: _ctx.resizeIsBig ? 'unfold_less' : 'unfold_more',
                color: "gray",
                flat: "",
                onClick: _ctx.handleResize
              }, null, 8, ["icon", "onClick"])
            ]),
            _: 1
          }, 8, ["message"]),
          (!_ctx.resource.expandSearchBox)
            ? (_openBlock(), _createBlock(_component_anp_popover, {
                key: 1,
                message: _ctx.$t('common.search')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_button, {
                    icon: "search",
                    color: "primary",
                    flat: "",
                    onClick: _ctx.toggleSearch
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["message"]))
            : _createCommentVNode("", true),
          _createVNode(_component_anp_popover, {
            message: _ctx.$t('common.options')
          }, {
            default: _withCtx(() => [
              (
            _ctx.workspace.notifiable != null &&
            _ctx.workspace.notifiable != undefined &&
            _ctx.workspace.notifiable
          )
                ? (_openBlock(), _createBlock(_component_Settings, {
                    key: 0,
                    onDoneEdit: _ctx.handleReload,
                    resource: _ctx.resource,
                    workspace: _ctx.workspace
                  }, null, 8, ["onDoneEdit", "resource", "workspace"]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["message"])
        ]),
        fixed: _withCtx(() => [
          (
          (_ctx.resource.hasEventContext || _ctx.resource.hasPlannedContext) &&
          !_ctx.showSearch
        )
            ? (_openBlock(), _createBlock(_component_LiveAgenda, {
                key: 0,
                resource: _ctx.resource,
                workspace: _ctx.workspace
              }, null, 8, ["resource", "workspace"]))
            : _createCommentVNode("", true),
          (_ctx.resource.expandSearchBox)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                style: _normalizeStyle({
          display: _ctx.showSearch || _ctx.resource.expandSearchBox ? 'block' : 'none',
        })
              }, [
                _createVNode(_component_va_input, {
                  ref: "input",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.search) = $event)),
                  placeholder: `Zoek in ${_ctx.resource.title}...`,
                  onKeyup: _ctx.resetStateAndHandleKey
                }, {
                  appendInner: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                      (!_ctx.searchLoading)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 0,
                            message: _ctx.$t('common.search')
                          }, {
                            default: _withCtx(() => [
                              (_ctx.resource.expandSearchBox && !_ctx.showSearch)
                                ? (_openBlock(), _createBlock(_component_va_button, {
                                    key: 0,
                                    icon: "arrow_forward",
                                    color: "dark",
                                    flat: "",
                                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleSearch(), _ctx.handleSearch()))
                                  }))
                                : (_openBlock(), _createBlock(_component_va_button, {
                                    key: 1,
                                    icon: "arrow_forward",
                                    color: "dark",
                                    flat: "",
                                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleSearch()))
                                  }))
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : (_openBlock(), _createBlock(_component_va_progress_circle, {
                            key: 1,
                            size: "small",
                            thickness: 0.2,
                            indeterminate: ""
                          }, null, 8, ["thickness"])),
                      (!_ctx.resource.expandSearchBox || _ctx.showSearch)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 2,
                            message: _ctx.$t('actions.close')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_button, {
                                icon: "close",
                                color: "dark",
                                flat: "",
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleSearch()))
                              })
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "onKeyup"])
              ], 4))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                style: _normalizeStyle({
          display: _ctx.showSearch || _ctx.resource.expandSearchBox ? 'block' : 'none',
        })
              }, [
                _createVNode(_component_va_input, {
                  ref: "input",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.search) = $event)),
                  placeholder: `Zoek in ${_ctx.resource.title}...`,
                  onKeyup: _ctx.handleKey
                }, {
                  appendInner: _withCtx(() => [
                    _createElementVNode("div", _hoisted_2, [
                      (!_ctx.searchLoading)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 0,
                            message: _ctx.$t('common.search')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_button, {
                                icon: "arrow_forward",
                                color: "dark",
                                flat: "",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.handleSearch()))
                              })
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : (_openBlock(), _createBlock(_component_va_progress_circle, {
                            key: 1,
                            size: "small",
                            thickness: 0.2,
                            indeterminate: ""
                          }, null, 8, ["thickness"])),
                      (_ctx.showSearch)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 2,
                            message: _ctx.$t('actions.close')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_button, {
                                icon: "close",
                                color: "dark",
                                flat: "",
                                onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.toggleSearch()))
                              })
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "onKeyup"])
              ], 4)),
          (_ctx.resource.hasDefaultSearches)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_DropdownSearches, {
                  ref: "dropdown",
                  resource: _ctx.resource,
                  workspace: _ctx.workspace,
                  showSearch: _ctx.showSearch,
                  onHandleSearches: _ctx.handleSearch,
                  onOpenSearch: _ctx.openSearch,
                  onResetField: _ctx.resetField,
                  onResetSearch: _ctx.resetSearch
                }, null, 8, ["resource", "workspace", "showSearch", "onHandleSearches", "onOpenSearch", "onResetField", "onResetSearch"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.resource.hasSections)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_DropdownSections, {
                  ref: "dropdownSections",
                  resource: _ctx.resource,
                  workspace: _ctx.workspace,
                  showSearch: _ctx.showSearch,
                  onHandleSearches: _ctx.handleSearch,
                  onOpenSearch: _ctx.openSearch,
                  onCloseSearch: _ctx.closeSearch,
                  onResetSearch: _ctx.resetSearch
                }, null, 8, ["resource", "workspace", "showSearch", "onHandleSearches", "onOpenSearch", "onCloseSearch", "onResetSearch"])
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          (_ctx.renderItems.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.renderItems, (renderItem) => {
                return (_openBlock(), _createBlock(_component_anp_column_item, {
                  key: renderItem.id,
                  item: renderItem,
                  resourceId: _ctx.resource.resourceId,
                  resource: _ctx.resource,
                  articleRouteName: _ctx.articleRoute,
                  articleIds: _ctx.renderItemIds,
                  searchContext: _ctx.showSearch,
                  showDescription: 
          _ctx.resource.settings?.showSnippet ||
          _ctx.resource.settings?.showDetails ||
          renderItem.kind === 'PERSON'
        ,
                  showImage: 
          _ctx.resource.settings?.showImages || renderItem.kind === 'PERSON'
        ,
                  showMeta: !_ctx.resource.settings?.showCompact,
                  showSource: 
          _ctx.resource.resourceKind == 'QUERY' ||
          _ctx.resource.resourceId.startsWith('NEWSLETTER') ||
          _ctx.resource.resourceKind == 'NEWSLETTERCONTENT'
        
                }, null, 8, ["item", "resourceId", "resource", "articleRouteName", "articleIds", "searchContext", "showDescription", "showImage", "showMeta", "showSource"]))
              }), 128))
            : (!_ctx.showSearch && !_ctx.allLoaded && !_ctx.renderItems.length)
              ? (_openBlock(), _createBlock(_component_anp_column_skeleton, {
                  key: 1,
                  text: "",
                  meta: ""
                }))
              : (!_ctx.showSearch && _ctx.isEmpty)
                ? (_openBlock(), _createBlock(_component_anp_column_empty, {
                    key: 2,
                    type: "news"
                  }))
                : _createCommentVNode("", true),
          (!_ctx.showSearch && _ctx.allLoaded && _ctx.renderItems[0]?.kind !== 'PERSON')
            ? (_openBlock(), _createBlock(_component_anp_column_all_loaded, { key: 3 }))
            : (_ctx.loadingMore)
              ? (_openBlock(), _createBlock(_component_anp_column_loader, { key: 4 }))
              : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["class", "id", "title", "anchor", "onScrollTop"]))
    : (_openBlock(), _createBlock(_component_anp_column, {
        key: 1,
        class: _normalizeClass({ big: _ctx.resizeIsBig }),
        id: _ctx.anchor,
        title: _ctx.resource.title,
        anchor: _ctx.anchor,
        onScrollTop: _ctx.handleScrollTop,
        ref: 
      (element) => {
        _ctx.root = element?.$el;
      }
    
      }, {
        actions: _withCtx(() => [
          (_ctx.draggable)
            ? (_openBlock(), _createBlock(_component_anp_popover, {
                key: 0,
                message: _ctx.$t('actions.drag')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_icon, {
                    class: "handle",
                    name: "drag_handle",
                    color: "gray"
                  })
                ]),
                _: 1
              }, 8, ["message"]))
            : _createCommentVNode("", true),
          _createVNode(_component_anp_popover, {
            message: 
          _ctx.resizeIsBig ? _ctx.$t('common.make-smaller') : _ctx.$t('common.make-wider')
        
          }, {
            default: _withCtx(() => [
              _createVNode(_component_va_button, {
                class: "resize",
                icon: _ctx.resizeIsBig ? 'unfold_less' : 'unfold_more',
                color: "gray",
                flat: "",
                onClick: _ctx.handleResize
              }, null, 8, ["icon", "onClick"])
            ]),
            _: 1
          }, 8, ["message"]),
          (!_ctx.resource.expandSearchBox)
            ? (_openBlock(), _createBlock(_component_anp_popover, {
                key: 1,
                message: _ctx.$t('common.search')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_va_button, {
                    icon: "search",
                    color: "primary",
                    flat: "",
                    onClick: _ctx.toggleSearch
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["message"]))
            : _createCommentVNode("", true),
          _createVNode(_component_anp_popover, {
            message: _ctx.$t('common.options')
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Settings, {
                onDoneEdit: _ctx.handleReload,
                resource: _ctx.resource,
                workspace: _ctx.workspace
              }, null, 8, ["onDoneEdit", "resource", "workspace"])
            ]),
            _: 1
          }, 8, ["message"])
        ]),
        fixed: _withCtx(() => [
          (
          (_ctx.resource.hasEventContext || _ctx.resource.hasPlannedContext) &&
          !_ctx.showSearch
        )
            ? (_openBlock(), _createBlock(_component_LiveAgenda, {
                key: 0,
                resource: _ctx.resource,
                workspace: _ctx.workspace
              }, null, 8, ["resource", "workspace"]))
            : _createCommentVNode("", true),
          (_ctx.resource.expandSearchBox)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                style: _normalizeStyle({
          display: _ctx.showSearch || _ctx.resource.expandSearchBox ? 'block' : 'none',
        })
              }, [
                _createVNode(_component_va_input, {
                  ref: "input",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.search) = $event)),
                  placeholder: `Zoek in ${_ctx.resource.title}...`,
                  onKeyup: _ctx.resetStateAndHandleKey
                }, {
                  appendInner: _withCtx(() => [
                    _createElementVNode("div", _hoisted_5, [
                      (!_ctx.searchLoading)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 0,
                            message: _ctx.$t('common.search')
                          }, {
                            default: _withCtx(() => [
                              (_ctx.resource.expandSearchBox && !_ctx.showSearch)
                                ? (_openBlock(), _createBlock(_component_va_button, {
                                    key: 0,
                                    icon: "arrow_forward",
                                    color: "dark",
                                    flat: "",
                                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.toggleSearch(), _ctx.handleSearch()))
                                  }))
                                : (_openBlock(), _createBlock(_component_va_button, {
                                    key: 1,
                                    icon: "arrow_forward",
                                    color: "dark",
                                    flat: "",
                                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.handleSearch()))
                                  }))
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : (_openBlock(), _createBlock(_component_va_progress_circle, {
                            key: 1,
                            size: "small",
                            thickness: 0.2,
                            indeterminate: ""
                          }, null, 8, ["thickness"])),
                      (!_ctx.resource.expandSearchBox || _ctx.showSearch)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 2,
                            message: _ctx.$t('actions.close')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_button, {
                                icon: "close",
                                color: "dark",
                                flat: "",
                                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.toggleSearch()))
                              })
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "onKeyup"])
              ], 4))
            : (_openBlock(), _createElementBlock("div", {
                key: 2,
                style: _normalizeStyle({
          display: _ctx.showSearch ? 'block' : 'none',
        })
              }, [
                _createVNode(_component_va_input, {
                  ref: "input",
                  modelValue: _ctx.search,
                  "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.search) = $event)),
                  placeholder: `Zoek in ${_ctx.resource.title}...`,
                  onKeyup: _ctx.handleKey
                }, {
                  appendInner: _withCtx(() => [
                    _createElementVNode("div", _hoisted_6, [
                      (!_ctx.searchLoading)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 0,
                            message: _ctx.$t('common.search')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_button, {
                                icon: "arrow_forward",
                                color: "dark",
                                flat: "",
                                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.handleSearch()))
                              })
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : (_openBlock(), _createBlock(_component_va_progress_circle, {
                            key: 1,
                            size: "small",
                            thickness: 0.2,
                            indeterminate: ""
                          }, null, 8, ["thickness"])),
                      (_ctx.showSearch)
                        ? (_openBlock(), _createBlock(_component_anp_popover, {
                            key: 2,
                            message: _ctx.$t('actions.close')
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_va_button, {
                                icon: "close",
                                color: "dark",
                                flat: "",
                                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.toggleSearch()))
                              })
                            ]),
                            _: 1
                          }, 8, ["message"]))
                        : _createCommentVNode("", true)
                    ])
                  ]),
                  _: 1
                }, 8, ["modelValue", "placeholder", "onKeyup"])
              ], 4)),
          (_ctx.resource.hasDefaultSearches)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_DropdownSearches, {
                  ref: "dropdown",
                  resource: _ctx.resource,
                  workspace: _ctx.workspace,
                  showSearch: _ctx.showSearch,
                  onHandleSearches: _ctx.handleSearch,
                  onOpenSearch: _ctx.openSearch,
                  onResetField: _ctx.resetField,
                  onResetSearch: _ctx.resetSearch
                }, null, 8, ["resource", "workspace", "showSearch", "onHandleSearches", "onOpenSearch", "onResetField", "onResetSearch"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.resource.hasRegions)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(_component_DropdownExpertRegions, {
                  ref: "dropdownRegions",
                  resource: _ctx.resource,
                  workspace: _ctx.workspace,
                  showSearch: _ctx.showSearch,
                  onHandleSearches: _ctx.handleSearch,
                  onOpenSearch: _ctx.openSearch,
                  onResetField: _ctx.resetField,
                  onResetSearch: _ctx.resetSearch
                }, null, 8, ["resource", "workspace", "showSearch", "onHandleSearches", "onOpenSearch", "onResetField", "onResetSearch"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.resource.hasSections)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_DropdownSections, {
                  ref: "dropdownSections",
                  resource: _ctx.resource,
                  workspace: _ctx.workspace,
                  showSearch: _ctx.showSearch,
                  onHandleSearches: _ctx.handleSearch,
                  onOpenSearch: _ctx.openSearch,
                  onCloseSearch: _ctx.closeSearch,
                  onResetSearch: _ctx.resetSearch
                }, null, 8, ["resource", "workspace", "showSearch", "onHandleSearches", "onOpenSearch", "onCloseSearch", "onResetSearch"])
              ]))
            : _createCommentVNode("", true)
        ]),
        default: _withCtx(() => [
          (_ctx.renderItems.length && _ctx.showSearch)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.renderItems, (renderItem) => {
                return (_openBlock(), _createBlock(_component_anp_column_item, {
                  key: renderItem.id,
                  item: renderItem,
                  resourceId: _ctx.resource.resourceId,
                  resource: _ctx.resource,
                  articleRouteName: _ctx.articleRoute,
                  articleIds: _ctx.renderItemIds,
                  searchContext: _ctx.showSearch,
                  showDescription: 
          _ctx.resource.settings?.showSnippet ||
          _ctx.resource.settings?.showDetails ||
          renderItem.kind === 'PERSON'
        ,
                  showImage: 
          _ctx.resource.settings?.showImages || renderItem.kind === 'PERSON'
        ,
                  showMeta: !_ctx.resource.settings?.showCompact,
                  showSource: 
          _ctx.resource.resourceKind == 'QUERY' ||
          _ctx.resource.resourceId.startsWith('NEWSLETTER') ||
          _ctx.resource.resourceKind == 'NEWSLETTERCONTENT'
        
                }, null, 8, ["item", "resourceId", "resource", "articleRouteName", "articleIds", "searchContext", "showDescription", "showImage", "showMeta", "showSource"]))
              }), 128))
            : (!_ctx.renderItems.length && _ctx.showSearch)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                  (_ctx.showSearch && _ctx.isEmpty)
                    ? (_openBlock(), _createBlock(_component_anp_column_empty, {
                        key: 0,
                        type: "source"
                      }))
                    : _createCommentVNode("", true)
                ], 64))
              : (_openBlock(), _createBlock(_component_anp_placeholder, { key: 2 }))
        ]),
        _: 1
      }, 8, ["class", "id", "title", "anchor", "onScrollTop"]))
}